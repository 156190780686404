import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import Pulse from '@ubisend/pulse-boost';

import logo from './logo.png';

Pulse.render({
  partner: {
    logo,
    url: 'https://atos.net/en/',
    policy_name:
      'By creating my account I agree to the ubisend End User License Agreement.',
    policy_url:
      'https://s3.eu-west-2.amazonaws.com/ubisend.legals/ubisend-EULA.pdf'
  }
});
